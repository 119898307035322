// Generated by Framer (7121ba0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["K4VXgNehk", "MbuiKqID5", "qV4Vz5G3p"];

const serializationHash = "framer-UHUqr"

const variantClassNames = {K4VXgNehk: "framer-v-3gzi3b", MbuiKqID5: "framer-v-rai8iw", qV4Vz5G3p: "framer-v-63tcgr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 6, ease: [0, 0, 1, 1], type: "tween"}, qV4Vz5G3p: {duration: 0, type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"No Load": "qV4Vz5G3p", "Variant 1": "K4VXgNehk", "Variant 2": "MbuiKqID5"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "K4VXgNehk"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "K4VXgNehk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1vjy44f = activeVariantCallback(async (...args) => {
setVariant("MbuiKqID5")
})

useOnVariantChange(baseVariant, {default: onAppear1vjy44f, qV4Vz5G3p: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-3gzi3b", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"K4VXgNehk"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-5ec3924c-869e-48d7-ba41-1018b0e5763f, rgb(229, 217, 201))", ...style}} {...addPropertyOverrides({MbuiKqID5: {"data-framer-name": "Variant 2"}, qV4Vz5G3p: {"data-framer-name": "No Load", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1k9tx7b"} layoutDependency={layoutDependency} layoutId={"NP724APFo"} style={{backgroundColor: "var(--token-67b76f32-6a47-4939-8c3f-150274fdd67c, rgb(36, 48, 16))"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-UHUqr[data-border=\"true\"]::after, .framer-UHUqr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UHUqr.framer-11war3y, .framer-UHUqr .framer-11war3y { display: block; }", ".framer-UHUqr.framer-3gzi3b { height: 4px; overflow: visible; position: relative; width: 322px; }", ".framer-UHUqr .framer-1k9tx7b { flex: none; height: 100%; left: 0px; overflow: visible; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 0%; }", ".framer-UHUqr.framer-v-rai8iw .framer-1k9tx7b { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 322
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MbuiKqID5":{"layout":["fixed","fixed"]},"qV4Vz5G3p":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerfeId2KHzN: React.ComponentType<Props> = withCSS(Component, css, "framer-UHUqr") as typeof Component;
export default FramerfeId2KHzN;

FramerfeId2KHzN.displayName = "Loadbar";

FramerfeId2KHzN.defaultProps = {height: 4, width: 322};

addPropertyControls(FramerfeId2KHzN, {variant: {options: ["K4VXgNehk", "MbuiKqID5", "qV4Vz5G3p"], optionTitles: ["Variant 1", "Variant 2", "No Load"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerfeId2KHzN, [])